/** @jsx jsx */
import { SvgIcon } from "@material-ui/core";
import dayjs from "dayjs";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Box, Container, jsx, Themed } from "theme-ui";
import BreadCrumbs from "../components/BreadCrumbs";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Logo from "../components/Logo";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";
import TableOfContents from "../components/TableOfContents";
import { LocalizationContext } from "../context/LocalizationContext";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { generatePDF } from "../utils/api";

const InsuranceTermsPageTemplate = ({ data, pageContext }) => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";
  const [PDF, setPDF] = useState(false);
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const [tocItems, setTocItems] = useState([]);

  useEffect(() => {
    setClient(true);
    initItems();
    const queryString = window.location.search;
    if (queryString.includes("print")) {
      setPrint(true);
    }
  }, []);

  const initItems = () => {
    const h2Elements = document.querySelectorAll("h2[id*='link-id']");
    var tocItemsTemp = [];
    h2Elements.forEach((element) => {
      const itemToadd = {
        text: element.innerText,
        id: element.id,
      };
      tocItemsTemp.push(itemToadd);

      element.setAttribute("role", "heading");
      element.setAttribute("aria-level", "2");
      element.setAttribute("tabindex", "0");
      element.style.scrollMarginTop = !isMobile ? "150px" : "100px";
    });
    setTocItems(tocItemsTemp);

    const p = document.querySelectorAll("p");
    p.forEach((element) => {
      element.setAttribute("tabindex", "-1");
    });
  };

  const handleClick = async () => {
    setPDF(true);
    setLoading(true);
    const pdfUrl = await generatePDF(slug, title);
    setGeneratedPdfUrl(pdfUrl.url);
    setLoading(false);
    download(null, pdfUrl.url);
  };

  const {
    pageType,
    title,
    navigationTitle,
    validityStartDate,
    siteSearchKeywords,
    metaDescription,
    contentArea,
    pdfUrlFi,
    pdfUrlSv,
    hidden,
    noIndex,
    slug,
    titleImage,
    updatedAt,
  } = data.contentfulInsuranceTermsPage;
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();

  const previous = pageContext.prev;
  const next = pageContext.next;
  const { basePath, node_locale: locale, language } = pageContext;

  const metadataTitle = title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metadataKeywords = siteSearchKeywords ? siteSearchKeywords : [];

  let metaImage = defaultImage;

  const validDate = new Date(+validityStartDate);
  const momentObj = dayjs(validDate);
  const metadataDateValid = momentObj.format("YYYY-MM-DD hh:mm:ssZ");
  const date = new Date(+validDate);

  const { translate } = useContext(LocalizationContext);

  const startDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;

  const download = (event, downloadUrl) => {
    let a = document.createElement("a");
    a.style = "display: none";
    a.href = downloadUrl ? downloadUrl : generatedPdfUrl;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const PdfRenderer = () => (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
          }}
        >
          <Themed.h3>{translate("terms.loadingPDF", null, language)}</Themed.h3>
        </Box>
      ) : (
        <Box
          id="download-button"
          onClick={download}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            color: "#00850d",
          }}
        >
          <Themed.h3 style={{ display: "inline", cursor: "pointer" }}>
            {translate("terms.downloadPDF", null, language)}
          </Themed.h3>
          <SvgIcon
            fontSize={"large"}
            component={"svg"}
            viewBox={"0 0 45 45"}
            style={{ display: "inline", marginLeft: 10 }}
          >
            <svg>
              <path d="M19.58594,4,9,14.58594V33h2V16H21V6H37V42H10v2H39V4ZM12.41406,14,19,7.41406V14Z" />
              <polygon points="15 33 15 38 33 38 33 33 31 33 31 36 17 36 17 33 15 33" />
              <polygon points="25 33 25 22.414 27.293 24.707 28.707 23.293 24 18.586 19.293 23.293 20.707 24.707 23 22.414 23 33 25 33" />
            </svg>
          </SvgIcon>
        </Box>
      )}
    </div>
  );

  const pdfLinkToShow = () => {
    let staticPdfUrl;
    if (locale === "fi-FI") {
      staticPdfUrl = pdfUrlFi;
    } else if (locale === "sv-SE") {
      staticPdfUrl = pdfUrlSv;
    }
    if (!staticPdfUrl) {
      if (isClient && PDF) {
        return <PdfRenderer key={key} />;
      } else {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 35,
              height: 50,
            }}
          >
            <Themed.h3
              onClick={handleClick}
              style={{
                color: "#00850d",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {translate("terms.createPDF", null, language)}
            </Themed.h3>
          </Box>
        );
      }
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            marginTop: 35,
            color: "#00850d",
            textDecoration: "none",
          }}
        >
          <Link
            target={staticPdfUrl}
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {translate("terms.downloadPDF", null, language)}
          </Link>
        </Box>
      );
    }
  };

  if (!print) {
    return (
      <Layout pageContext={pageContext} pageType={pageType}>
        <SEO
          lang={pageContext.language}
          title={metadataTitle}
          description={metadataDescription}
          keywords={metadataKeywords}
          image={metaImage}
          paths={pageContext.paths}
          hidden={hidden}
          noIndex={noIndex}
        />
        <Container>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            language={language}
          />
        </Container>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Container
            sx={{
              width: ["100%", null, null, "35%"],
              pb: [0, null, null, 64],
            }}
          >
            {tocItems.length > 0 && (
              <TableOfContents language={language} tocItems={tocItems} />
            )}
          </Container>
          <Container
            variant="narrow"
            id="content"
            sx={{ py: [3, null, 5], width: ["100%", null, null, "65%"] }}
          >
            {titleImage ? (
              <GatsbyImage
                image={titleImage.gatsbyImageData}
                alt={titleImage.description ? titleImage.description : ""}
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              />
            ) : (
              ""
            )}
            <Themed.h1 tabIndex="0">{title}</Themed.h1>
            <Themed.p
              sx={{
                mb: [3, 5],
              }}
            >
              {translate("terms.valid", null, language)} {startDate}{" "}
              {translate("terms.starting", null, language)}
            </Themed.p>
            {contentArea && contentArea.raw && (
              <RichText2
                content={contentArea}
                locale={locale}
                language={language}
                template="insuranceTerms"
              />
            )}
            {pdfLinkToShow()}
          </Container>
        </Container>
      </Layout>
    );
  } else {
    return (
      <Container variant="wide">
        {print ? (
          <div style={{ marginBottom: 15 }} aria-label="logo">
            <Logo />
          </div>
        ) : (
          ""
        )}
        {titleImage ? (
          <Box id="title-image">
            <GatsbyImage
              image={titleImage.gatsbyImageData}
              alt={titleImage.description ? titleImage.description : ""}
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        ) : (
          ""
        )}
        <Themed.h1 tabIndex="0">{title}</Themed.h1>
        <Themed.p
          sx={{
            mb: [3, 5],
          }}
        >
          {translate("terms.valid", null, language)} {startDate}{" "}
          {translate("terms.starting", null, language)}
        </Themed.p>
        {tocItems.length > 0 && (
          <TableOfContents
            type="print"
            language={language}
            tocItems={tocItems}
          />
        )}
          {contentArea && contentArea.raw && (
            <RichText2
              content={contentArea}
              locale={locale}
              language={language}
              template="insuranceTerms"
            />
          )}
      </Container>
    );
  }
};

export const query = graphql`
  query ($id: String!) {
    contentfulInsuranceTermsPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      siteSearchKeywords
      pdfUrlFi
      pdfUrlSv
      hidden
      titleImage {
        gatsbyImageData(quality: 85, width: 1080, aspectRatio: 3)
        description
      }
      metaDescription {
        internal {
          content
          type
        }
      }
      validityStartDate(formatString: "x")
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ... on Node {
            ...fragmentContentfulComponent
            ...fragmentContentfulAccordion
            ...fragmentContentfulLiftUpColor
            ...fragmentContentfulInlineImage
            ...fragmentContentfulAsset
            ...fragmentContentfulCoverageGrid
            ...fragmentContentfulLiftUpEasyStep
            ...fragmentContentfulTwoColumns
            ...fragmentContentfulCallToAction
          }
        }
      }
      slug
      updatedAt
    }
  }
`;
export default InsuranceTermsPageTemplate;
