import React, { useContext } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import { jsx, Themed, Container, Heading } from "theme-ui";

const TableOfContents = ({ tocItems, language, type }) => {
  const { translate } = useContext(LocalizationContext);

  const handleClick = (id) => {
    document.getElementById(id).focus();
  };

  if (!type) {
    return (
      <Container
        sx={{
          backgroundColor: "muted",
          py: 2,
          position: "sticky",
          top: "25%",
          overflow: "auto",
          maxHeight: "75vh",
        }}
        id="table-of-contents"
      >
        <Heading
          as="h3"
          sx={{
            fontSize: [4, 4, 5],
            mt: 2,
          }}
        >
          {" "}
          {translate("terms.tableOfContents", null, language)}{" "}
        </Heading>

        {tocItems.map((item, i) => {
          const link = `#${item.id}`;
          const ariaLabel =
            item.index > 1 ? `${item.text} ${item.index}` : `${item.text}`;
          return (
            <a
              href={link}
              key={item.id}
              aria-label={ariaLabel}
              offset="150"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => handleClick(item.id)}
            >
              <Themed.h3 key={i}>{item.text}</Themed.h3>
            </a>
          );
        })}
      </Container>
    );
  }
  if (type === "print") {
    return (
      <Container
        sx={{
          backgroundColor: "muted",
          py: 2
        }}
        id="table-of-contents"
      >
        <Themed.h3>
          {" "}
          {translate("terms.tableOfContents", null, language)}{" "}
        </Themed.h3>
        {tocItems.map((item, i) => {
          const link = `#${item.id}`;
          return (
            <a
              href={link}
              key={item.id}
              offset="300"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => handleClick(item.id)}
            >
              <Themed.h3 key={i}>{item.text}</Themed.h3>
            </a>
          );
        })}
      </Container>
    );
  }
};

export default TableOfContents;
